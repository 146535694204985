<template>
  <v-app>
    <Content>
      <router-view :key="getKey"></router-view>
    </Content>
    <Alert />
  </v-app>
</template>

<script>
import Content from "@/components/layouts/Content/Content.vue";

export default {
  components: { Content },
  computed: {
    getKey() {
      //  rerender page components when route params will change
      const { name, params } = this.$route;
      return name + JSON.stringify(params);
    },
  },
};
</script>
